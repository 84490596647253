/*
  for convenience, you can also set environment variable APP_BACKEND_HOST in .env file in project root directory
  this is ignored by git and is persistent
  NOTE: changes to .env file will require dev server restart
*/

export const HOST =  "https://dev10.phoenix-backend-staging.com/api";
//  process.env.APP_BACKEND_HOST ?? "https://phoenix-backend-production.com/api";

export const DOCUSIGN_HOST =
  process.env.DOCUSIGN_HOST ?? "https://c-papyrus.com";
